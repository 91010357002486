import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FooterService {
    public loginUserUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public pageName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    constructor() { }

    next(val: string) {
        this.loginUserUrl.next(val);
      }

    nextPage(val: string) {
    this.pageName.next(val);
    }
}
