import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { TuiRootModule, TuiDialogModule, TuiNotificationsModule, TUI_SANITIZER, TuiAlertModule } from "@taiga-ui/core";
import { BrowserModule, TransferState, makeStateKey, StateKey } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './http/interceptor/httpconfig.interceptor';
import { Api } from './http/Api';
import { AppRoutingModule } from './app-routing.module';
import { ServicesModuleModule } from './services-module.module';
import { MaterialModule } from './material-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashScreenModule } from './theme/core/splash-screen/splash-screen.module';

/** Apollo Angular */
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import { AppSettings } from '@isb-technologies/app.settings';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedTitleService } from "@isb-technologies/framework/services/shared-title.service";
import { DeveloperModeHelper } from "@isb-technologies/framework/developer/developer-mode.helper";
import { AuthenticationModule } from "@isb-technologies/authentication/authentication.module";
import { AuthGuard } from "@isb-technologies/authentication/_guards";
import { AuthService } from "@isb-technologies/authentication/services/auth.service";
import { ToastsContainer } from "./services/toasts-container.component";
import { FooterService } from "./services/footer.service";


/** ENDOF - Apollo Angular */

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // FooterComponent,
    // HomeComponent,
    ToastsContainer
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserTransferStateModule,
    ServicesModuleModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SplashScreenModule,
    NgxSpinnerModule,
    // FindHouseHomeModule,
    AuthenticationModule.forRoot(),
    AppRoutingModule,
    NgbModule,
      TuiRootModule,
      TuiDialogModule,
      TuiNotificationsModule,
      TuiAlertModule
],
  providers: [
    AuthService,
    SharedTitleService,
    FooterService,
    DeveloperModeHelper,
    Api, 
  
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: `${AppSettings.API_GRAPHQL}`,
          }),
        };
      },
      deps: [HttpLink],
    },    
    {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}
],
  bootstrap: [AppComponent]
})
export class AppModule { }
