import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnhancedAuthGuard } from '@isb-technologies/authentication/_guards/enhanced-auth.guard';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { NotFoundComponent } from './notfound/not-found.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'findhouse' },
  {
    path: '',
    canActivate: [EnhancedAuthGuard],
    loadChildren: () => import('./theme/layout/layout.module').then(m => m.LayoutModule)
  },
  // { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
  // { path: 'home', pathMatch: 'full', component: FindHouseHomeComponent },
  // { path: 'home', component: HomeComponent },
  {
    path: 'errors/404',
    component: NotFoundComponent
  },
  {
    path: 'errors/not-allowed',
    component: NotAllowedComponent
  },
  { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
  
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
