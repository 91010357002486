import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';



import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the access token from the service.
    // const authHeader = this._authService.getAccessToken(); // - Example of how we could get access token from authService which could obtain it from localStorage
    
    const token = this.authService.currUser()?.access_token;

    if(!token) {
      return next.handle(req);
    }
    const authHeader = `Bearer ${token}`;

    // Clone the request to add the new header.
    const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }
}