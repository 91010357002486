// import { AppSettings } from './shared/classes/app.settings';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { ModuleWithProviders, NgModule } from '@angular/core';
// import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
// import { HttpClientModule } from '@angular/common/http';
// import { Apollo, ApolloModule } from 'apollo-angular';
// import { DataProxyExtensions } from './apollo-proxy.extensions';

import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { DeveloperConstants } from "@isb-technologies/framework/developer/developer-constants";
import { DeveloperModeHelper } from "@isb-technologies/framework/developer/developer-mode.helper";
import { NgxPermissionsModule, NgxPermissionsService } from "ngx-permissions";
import { fromEvent } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AuthenticationRoutingModule } from "./authentication-routing.module";
import { AuthenticationModeHelper } from "./developer/authentication-mode.helper";
import { InterceptorsModule } from "./interceptors.module";
import { ApiService } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import { ProfileService } from "./services/profile.service";
import { AuthGuard } from "./_guards";
import { AuthRequiredGuard } from "./_guards/auth-required.guard";
import { EnhancedAuthGuard } from "./_guards/enhanced-auth.guard";

@NgModule({
  declarations: [],
  imports: [
    NgxPermissionsModule.forRoot(),
    InterceptorsModule,
    AuthenticationRoutingModule
  ],
  exports: [AuthenticationRoutingModule],
  providers: [],
  entryComponents: []
})
export class AuthenticationModule {

  static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [ 
        AuthenticationModeHelper,        
        AuthGuard, 
        EnhancedAuthGuard, 
        AuthRequiredGuard,   
        ApiService,
        ProfileService,
        NgxPermissionsService, 
        {
        provide: APP_INITIALIZER,
        useFactory: ( ps: NgxPermissionsService, dev: DeveloperModeHelper ) => function() {
            var permissions = JSON.parse(localStorage.getItem('permissions'));
            if(!permissions) {
              permissions = [];
            }
  
            if(permissions.find(_=>_ === 'DEVELOPER')) {
              dev.isDeveloper.next(true);
              var isDeveloperMode = JSON.parse(localStorage.getItem(DeveloperConstants.IS_DEVELOPER_MODE));
              if(isDeveloperMode) {
               dev.next(isDeveloperMode);
              } else {
                dev.next(false);
              }
            } else {
              localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
            }
  
            let storageEvent$ = fromEvent<StorageEvent>(window, 'storage')
            .pipe(
              filter(evt=>evt.key === DeveloperConstants.IS_DEVELOPER_MODE),
              map(evt => evt)
            ).subscribe((evt) => {
              dev.next(JSON.parse(evt.newValue));
              console.log(evt);
            })
  
            return ps.loadPermissions(permissions);
        },
        deps: [NgxPermissionsService, DeveloperModeHelper],
        multi: true
      }
    ]
    };
  }
  
  // constructor(
  //   apollo: Apollo,
  //   httpLink: HttpLink,
  // ) {

  //   apollo.create({
  //     cache: new InMemoryCache({ }),
  //     defaultOptions: {

  //       // watchQuery: {
  //       //   fetchPolicy: 'cache-and-network',
  //       //   errorPolicy: 'all',
  //       // },
  //       query: {
  //         fetchPolicy: 'no-cache',
  //         errorPolicy: 'all',
  //       },
  //       // watchQuery: {
  //       //   fetchPolicy: 'network-only',
  //       //   errorPolicy: 'all',
  //       // },
  //       // query: {
  //       //   fetchPolicy: 'network-only',
  //       //   errorPolicy: 'all',
  //       // },
  //       // mutation: {
  //       //   errorPolicy: 'all',
  //       // }
  //     },
  //     link: httpLink.create({
  //       uri: AppSettings.API_GRAPHQL,
  //       method: "POST",
  //       includeQuery: true,
  //     })
  //   });
  // }
}