import { FormBuilder } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { IBaseChildViewManagement } from "../base-child-view.interface";
import { ChildViewManagement } from "../child-view.management";
import { ComponentMode } from "./component-mode.enum";
import { PageViewModel } from "../_page/pageview.model";
import { Component } from "@angular/core";
import { DeveloperModeHelper } from "../developer/developer-mode.helper";

declare let mApp: any;

export interface IPageViewModelBasedComponent<TPageViewModel extends PageViewModel> extends IBaseChildViewManagement {
  pageViewModel$: BehaviorSubject<TPageViewModel>;
}

@Component({template:''})
export abstract class PageViewModelBasedComponent<TPageViewModel extends PageViewModel> extends ChildViewManagement implements IPageViewModelBasedComponent<TPageViewModel> {

  public COMPONENT_MODE: ComponentMode;
  
  public pageViewModel$: BehaviorSubject<TPageViewModel> = null;

  constructor(
    public _formBuilder: FormBuilder, 
    protected _dev: DeveloperModeHelper
    ) {
    super(_dev);

    this.pageViewModel$ = new BehaviorSubject<TPageViewModel>(null);
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}