export class FullAccount {
	constructor(
		public subjectId: string = null,
		public id: number = null,
		public code: string = null,
		public firstName: string = null,
    public lastName: string = null,
    public fullName : string = null,
		public email: string = null,

    public accountTypeCode: string = null,   
    
		) { }
}

export class Session {
  public IS_CONNECTED = this.session === 'ONLINE';
  
  constructor(
    public session: string = 'OFFLINE',
    public account: FullAccount = null) { }
}