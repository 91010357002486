import {map, tap} from 'rxjs/operators';

import { Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { User } from 'oidc-client';
import { AuthService } from '../services/auth.service';
import { from } from 'rxjs';


@Injectable()
export class EnhancedAuthGuard implements CanActivate {

    private user: User;

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
              var loggedRequest = this.authService.isUserLoggedIn()
        .pipe(
           map(_=> {
            return true;
          })
        );

        return loggedRequest;
    }
}
