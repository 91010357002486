import { BehaviorSubject, from, Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthenticationConstants } from "./authentication-constants";
import { AuthService } from "../services/auth.service";
import { AccountFullByTokenQuery } from "../auth/account-full-by-token.query";
import { map } from "rxjs/operators";
import { FullAccount, Session } from "../models/full-account.model";

@Injectable()
export class AuthenticationModeHelper {
  // public isDeveloper: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //JSON.parse(localStorage.getItem(DeveloperConstants.IS_DEVELOPER_MODE)));
  constructor() {
   
  }
  next(val: boolean) {
    
    localStorage.setItem(AuthenticationConstants.IS_AUTHENTICATION_MODULE_AVAILABLE, JSON.stringify(val));
    this.isAuthenticated.next(val);
  }

  account() : Observable<Session> {
    
   
    if(this.isAuthenticated.getValue() === true) {
    
      debugger;
      // If this is by GraphQL
      // var acc$ = this.account
      // // .watch(vars)
      // // .valueChanges
      // .fetch()
      // .pipe(map(result => {
      
      //   var item = (<any>result.data);
      //   var fullAccount = (<any>item).accountFullByToken.data as FullAccount;

      //   var session = new Session('ONLINE', fullAccount);

      //   return session;
      // }));
   

      return of(new Session('ONLINE'));
    }
     else {
       var offlineSession = new Session('OFFLINE', null);
       
       return of(offlineSession);
     }
  }

  // isActive() {
  //   this._auth.isUserLoggedIn()
  // }
}
