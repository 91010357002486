
import {map, tap} from 'rxjs/operators';
// import { Injectable } from "@angular/core";
// import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
// import { UserService } from "../_services/user.service";
// import { Observable } from "rxjs/Rx";

// @Injectable()
// export class AuthGuard implements CanActivate {

//     constructor(private _router: Router, private _userService: UserService) {
//     }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
//         let currentUser = JSON.parse(localStorage.getItem('currentUser'));
//         return this._userService.verify().map(
//             data => {
//                 if (data !== null) {
//                     // logged in so return true
//                     return true;
//                 }
//                 // error when verify so redirect to login page with the return url
//                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//                 return false;
//             },
//             error => {
//                 // error when verify so redirect to login page with the return url
//                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
//                 return false;
//             });
//     }
// }


import { Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { User } from 'oidc-client';
import { AuthService } from '../services/auth.service';
import { from } from 'rxjs';


@Injectable()
export class AuthRequiredGuard implements CanActivate {

    private user: User;

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let loggedIn = false;
        const claims = route.data;

        debugger;
        const self = this;

        debugger;
      
        sessionStorage.setItem("postSuccessfulLoginRedirectUrl", state.url);

        var loggedRequest = this.authService.isUserLoggedIn()
        .pipe(
          tap(_ => {
            debugger;
            
            if(_ === false) {
              this.router.navigate(['unauthorized']);   
            }
          })
        );

        return loggedRequest;
    }
}
