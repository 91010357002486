import { Component, Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import * as $ from 'jquery';
import { config } from './config';
import { Subscription } from 'rxjs';
import { SplashScreenService } from './theme/core/splash-screen/splash-screen.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  title = 'kinzi-ng-node';
  isBorwser: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router, private activatedRoute: ActivatedRoute,
    private splashScreenService: SplashScreenService,
    private spinner: NgxSpinnerService
    ) {
    this.isBorwser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   if (this.isBorwser) window.scrollTo(0, 0);      
    // });
    // this.router.events.subscribe((url: NavigationEnd) => {
    //   if (url && url.urlAfterRedirects) {
    //     if (this.isBorwser) {
    //       gtag('config', config.googleAnalyticsId,
    //         {
    //           'page_path': url.urlAfterRedirects
    //         }
    //       );
    //     }        
    //   }
    //   return;
    // });

    // const routerSubscription = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // hide splash screen
    //     this.splashScreenService.hide();
  
    //     if(this.isBorwser) {
    //     // scroll to top on every route change
    //       window.scrollTo(0, 0);
        
  
    //     // to display back the body content
    //     setTimeout(() => {
    //       document.body.classList.add('page-loaded');
    //     }, 500);
    //     }
    //   }
    // });
    // this.unsubscribe.push(routerSubscription);
  }

  
ngOnDestroy() {
  this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
