import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UserManager, Log, MetadataService, User } from 'oidc-client';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { CookieService } from 'ng2-cookies';
import { DeveloperConstants } from '@isb-technologies/framework/developer/developer-constants';
import { DeveloperModeHelper } from '@isb-technologies/framework/developer/developer-mode.helper';
import { Profile } from '../models/profile.model';
import { AuthService } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import { AuthenticationModeHelper } from '../developer/authentication-mode.helper';
import { ProfileBySubjectIdGQL } from '../graphql/profile-by-subject-id.query';

@Component({
  selector: 'app-auth',
  providers: [CookieService],
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss']
})
export class AuthComponent implements OnInit {
  //, private location:Location
  cookies: Object;

  constructor(private location: Location,
    private service: AuthService,
    // private cookieService: CookieService, 
    private router: Router,
    private _profileService: ProfileService,
    private _permissions: NgxPermissionsService,
    private _dev: DeveloperModeHelper,
    private _auth: AuthenticationModeHelper,
    private _ngxPermissions: NgxPermissionsService,
    private _profileQueryBySubjectId: ProfileBySubjectIdGQL,


    // private _script: ScriptLoaderService,
    // private _payslipService: PayslipService
    // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) {}

  ngOnInit() {
    // this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/default/base/scripts.bundle.js'], true)
    // .then(result => {
    //   Helpers.setLoading(false);
    //    });
    Log.logger = console;
    var locObject = this.location;
    var self = this;

    debugger;

    // var cookServ = this.cookieService;
    self.service.mgr.signinRedirectCallback().then(function (user: User) {
      if (user == null) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("error").innerText = "No sign-in request pending.";

        // this._auth.next(false);
      }
      else {
        // var appCookies = cookServ.getAll();
        // var redirectUrl = appCookies['postSuccessfulLoginRedirectUrl']
        // self.router.navigate([redirectUrl]);
        self.service.currentUser = user;
        self.service.userLoadededEvent.emit(user);
        
        let redirectUrl = sessionStorage.getItem('postSuccessfulLoginRedirectUrl')
        

        // Compute check if Profile exists for the first time here.
        var authenticatedUser = user;
        var userProfile = authenticatedUser.profile;
        var subjectId = userProfile.sub;

        // self._payslipService.checkExistingOtherLeave().subscribe(
        //   result => {
        //     debugger;
        //   }
        // );

        // this._auth.next(true);
      
          
   debugger     
 if (subjectId) {
  // self.router.navigate(['/profile', `${subjectId}`,'authorise']);

  debugger;
  self.onProfileCheck(subjectId, redirectUrl);

} else {
  debugger;
  self.router.navigateByUrl(redirectUrl);
}
       
      }
    })
      .catch(function (er) {
        console.log(er);
        console.log(er.message);

        // application not working page - error page - check metronic.
        self.router.navigate(['/auth-error']);

        // document.getElementById("waiting").style.display = "none";
        // document.getElementById("error").innerText = er.message;
      });
  }

  onProfileCheck(subjectId: string, redirectUrl: string) {
    // this._loadingService.showLoading('Please wait...');

    debugger;
    let vars = {
      subjectId: subjectId,
      assignedOnly: true
    };

    this._profileQueryBySubjectId
      // .watch(vars)
      // .valueChanges
      .fetch(vars)
      .pipe(map(result => {
      
        var item = (<any>result.data);
        return (<any>item).accountProfileBySubjectId.data as Profile;
      })
      ).subscribe((item: any) => {
        //this.item = item as Profile;
        
        if (item.id) {

          this.service.setApplicationProfileUser(item);


          var userRole = item.roles;
          var permissions = [];
          debugger;
          if(userRole)
                  if(userRole[0]) {
                    console.log(userRole[0]);
                    if(userRole[0].permissions && userRole[0].assigned)
                    // if(userRole[0].appRole.appRolePermissions) {
                      userRole[0].permissions.map((elem, i) => {
                        if(elem.assigned === true) {
                          permissions.push(elem.name);
                        }
                        }) 
                    // }
                  }


                  let identityRole = '';
                  if(identityRole = this.service.currentUser.profile.role) {
                    this._dev.isDeveloper.next(true);
                    //localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
                    permissions.push(identityRole);
                  } else {
                    this._dev.isDeveloper.next(false);
                    localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
                    // localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
                  }

                  

                  localStorage.setItem("permissions", JSON.stringify(permissions));
                  this._ngxPermissions.loadPermissions(permissions);
          
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate([`${subjectId}`, 'authorise']);
          // this._loadingService.hideLoading();
        }
      });
    // let vars = {
    //   subjectId: subjectId
    // };

    // this._profileService.getProfileBySubjectIdQuery(subjectId)
    //   .pipe(map(result => {
      
    //     var item = (<any>result);
    //     return (<any>item) as Profile;
    //   })
    //   ).subscribe((item: any) => {
    //     //this.item = item as Profile;
        
    //     if (item.id) {
    //       // this.service.appProf = item;
    //       this.service.setApplicationProfileUser(item);

    //       var userRole = item.appUserRoles;
    //       var permissions = [];
    //       if(userRole)
    //               if(userRole[0]) {
    //                 if(userRole[0].appRole) 
    //                 if(userRole[0].appRole.appRolePermissions) {
    //                   userRole[0].appRole.appRolePermissions.map((elem, i) => {
    //                       permissions.push(elem.appPermission.claimValue);
    //                     }) 
    //                 }
    //               }

    //               let identityRole = 'Developer';
    //               if(identityRole = this.service.currentUser.profile.role) {
    //                 this._dev.isDeveloper.next(true);
    //                 //localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
    //                 permissions.push(identityRole);
    //               } else {
    //                 this._dev.isDeveloper.next(false);
    //                 localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
    //                 // localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
    //               }

    //               localStorage.setItem("permissions", JSON.stringify(permissions));
    //              this._permissions.loadPermissions(permissions);
          
    //       this.router.navigateByUrl(redirectUrl);
    //     } else {
    //       this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
    //       // this._loadingService.hideLoading();
    //     }
    //   });
  }
}