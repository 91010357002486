// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * CONSTS
 */
export const BASE_URL = 'http://localhost';

export const API_PORT = '9082';
export const AUTH_PORT = '8002';
export const NOTIFS_PORT = '8003';
export const ANG_PORT = '4201';
export const PDF_PORT = '8010';

export const environment = {
    production: false,
    API_ENDPOINT: `https://ecards-api.leap.mt`,
    API_GRAPHQL: `https://ecards-api.leap.mt/api/graphql`,

    AUTHORITY_ENDPOINT: `https://id.isb.com.mt`,
    NOTIFICATIONS_API_ENDPOINT: `${BASE_URL}:${NOTIFS_PORT}/notification`,
    // AUTHORITY_ENDPOINT: `${BASE_URL}:${AUTH_PORT}`,

    CDN_ENDPOINT: `https://ecards-cdn.leap.mt/api/`,

    COMPANY_NAME: "ISB Technologies",
    COMPANY_URL: "https://isb.mt/",

     POST_LOGOUT_REDIRECT_URI: `https://ecards.leap.mt`,
     REDIRECT_URI: `https://ecards.leap.mt/auth`,
     SILENT_REDIRECT_URI: `https://ecards.leap.mt/silent`,

    // POST_LOGOUT_REDIRECT_URI: `${BASE_URL}:${ANG_PORT}`,
    // REDIRECT_URI: `${BASE_URL}:${ANG_PORT}/auth`,
    // SILENT_REDIRECT_URI: `${BASE_URL}:${ANG_PORT}/silent`,

    SCOPE: 'openid profile app ecards.api',

    AUTOMATIC_SILENT_RENEW: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
