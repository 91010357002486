import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { switchMap, map, tap } from 'rxjs/operators';
import { combineLatest, Observable, of} from 'rxjs';
import {  FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { PageViewModelBasedComponent } from '@isb-technologies/framework/form-view/page-view-model-based-component';
import { AccountProfileResult} from './account-profile-result.model';
import { Mutation, QueryRef } from 'apollo-angular';
import { ProfileAuthorisationPageViewModel } from './authorisation-pageviewmodel';
import { CreateProfileMutationGQL } from '../graphql/create-profile.mutation';
import { DeveloperModeHelper } from '@isb-technologies/framework/developer/developer-mode.helper';
import { ProfileBySubjectIdGQL } from '../graphql/profile-by-subject-id.query';
import { Profile } from '../models/profile.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-authorisation',
  templateUrl: './authorisation.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AuthorisationComponent extends PageViewModelBasedComponent<ProfileAuthorisationPageViewModel> implements OnInit, OnDestroy {

  public _appQueryInstance: QueryRef<{}, {}>;
  public _appMutationInstance: Mutation;

  
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,    
    private _profileBySubjectIdQuery: ProfileBySubjectIdGQL,
    private createProfileMutation: CreateProfileMutationGQL,
    private _auth: AuthService,    
    
    public _formBuilder: FormBuilder,
    protected _dev: DeveloperModeHelper) {
      super(_formBuilder, _dev)
      this._appQueryInstance = _profileBySubjectIdQuery.watch({}, { fetchPolicy: 'cache-and-network' });
      this._appMutationInstance = createProfileMutation;
    }

  ngOnInit() {

    super.ngOnInit();


    let onInit$ = combineLatest([this.appRouteParams$(), this.appRouteQueryParams$()])
    // Combining all these, we could get multiple different Event Sources to trigger our re-usable initialisation procedure.
      .pipe(
        tap(([RouteParams, QueryParams]) => {
          
            var email = RouteParams['email'];

            // At this point, since we are inside a TAP function we can hydrate an interim pageViewModel$ instance with the information that we know at this point.
            this.pageViewModel$.next({ ...this.pageViewModel$.getValue(), ...{ subjectId : email } });
          // }
        }),
        switchMap(([RouteParams, QueryParams]) => {          
          var email = RouteParams['email'];

            var MUT_VARS: any = this.__MUT_VARS();

            MUT_VARS = { ...MUT_VARS, ...{subjectId : email }};

            return this.appOnInit(MUT_VARS)
        })
      );

    // The subscribe grabs the result after the observable operators chain to update the PageViewModel and give it a read-only version of the results.
    let piped$ = (onInit$);
    
    let onInit = piped$.pipe(
      map((accountProfileResult: AccountProfileResult) => {
        return accountProfileResult.systemAccountProfile;
      }),
      switchMap((systemAccountProfile : Profile) => {

        if(systemAccountProfile && systemAccountProfile.id) {       
          
          return of(systemAccountProfile);
        } else {

          var authenticateUser = this._auth.currentUser;
          var profile = authenticateUser.profile;
        
        var MUT_VARS = { item: {          
          subjectId: profile.sub,
          firstName: profile.given_name,
          lastName: profile.family_name,
          email: profile.email
        }};

        return this.appMutationImpl(MUT_VARS);

        }
      })
    ).subscribe((accountProfile: Profile) => {
      console.log(accountProfile);

      let redirectUrl = sessionStorage.getItem('postSuccessfulLoginRedirectUrl')

      this.router.navigateByUrl(redirectUrl);
    });
    
    
    this.subscriptions$.push(onInit);

  }

  appOnInit(MUT_VARS) {
    let initQuery$ = this.appQueryImpl(MUT_VARS);
    return initQuery$;
  }

  appQueryImpl(vars: any) {
    let queryGQL = this._appQueryInstance;
    queryGQL.setVariables(vars);

    let o$ = of(queryGQL);

    let p$ = o$.pipe(
      switchMap((_) => _.refetch()),
      map(result => {
        const item = (<any>result).data;
        const systemAccountProfile = item ? (<any>item).accountProfileBySubjectId.data as Profile : null;     

        return {
          systemAccountProfile: systemAccountProfile
        } as AccountProfileResult;
      })
    );
    return p$;
  }

  appMutationImpl(vars: any) {
    let mutationGQL = this._appMutationInstance;
    let mutation$ = mutationGQL.mutate(vars);

    let o$ = mutation$;
    let p$ = o$.pipe(
      
      map(result => {
        const item = (<any>result).data;
        const systemAccountProfile = item ? (<any>item).createAccountProfile.data as Profile : null;
     
        return {
          systemAccountProfile: systemAccountProfile
        } as AccountProfileResult;
      }),
      map((accountProfileResult: AccountProfileResult) => {
        return accountProfileResult.systemAccountProfile;
      })
    );
    return p$;
  }

  private __MUT_VARS() {}

  appRouteParams$(): Observable<Params> {
		let params$ = this.activatedRoute.params;
		return params$;
	}

	appRouteQueryParams$(): Observable<Params> {
		let queryParams$ = this.activatedRoute.queryParams;
		return queryParams$;
	}
}